<template>
  <div>
    <v-row>
      <v-expansion-panels data-cy="" flat tile accordion>
        <v-expansion-panel class="mt-n3" data-cy="site_param_panel">
          <v-expansion-panel-header class="text"
            >Site Parameters</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-radio-group v-model="siteParam" class="pl-5" dense>

              <!-- mag field params-->
              <v-row v-if="latticeType !== 'spinless fermions'">
                <v-col lg="4" md="5" sm="7">
                  <v-radio
                    color="hqsOrange"
                    data-cy="magField"
                    class="magField mt"
                    label="Bx, By, Bz"
                    value="default"
                  ></v-radio>
                </v-col>
                <v-col lg="1" md="1" sm="5">
                  <InfoDialog
                    v-bind:dialogMessage="dialogMessage"
                    class="ml-n8"
                  ></InfoDialog>
                </v-col>
                <v-col cols="12">
                  <v-img
                    class="mag_img"
                    :src="require('../../assets/magFieldColors.png')"
                  ></v-img>
                </v-col>
              </v-row>

              <v-row v-if="latticeType.includes('spinless')">
                <v-col cols="4">
                  <v-radio
                    color="hqsOrange"
                    class="default"
                    data-cy="default"
                    label="default"
                    value="default"
                  ></v-radio>
                </v-col>
                <!--Todo: add default info here-->
                <v-col cols="1">
                  <InfoDialog
                    info="defaultColors"
                    class="ml-n8"
                  ></InfoDialog>
                </v-col>
              </v-row>

              <!-- onsite E (e0) + onsite U params-->
              <v-row v-if="latticeType !== 'spins'">
                <v-col cols="12">
                  <v-radio
                    color="hqsOrange"
                    data-cy="e0"
                    label="Onsite Energy"
                    value="e0"
                  ></v-radio>
                </v-col>
                <!-- currently not in use because qolossal does not need it -->
                <!-- <v-col
                  cols="12"
                  v-if="latticeType.includes('spinful')"
                >
                  <v-radio
                    color="hqsOrange"
                    data-cy="onsite_U"
                    label="Onsite U"
                    value="onsite_U"
                  ></v-radio>
                </v-col> -->
              </v-row>

              <v-row v-if="latticeType.includes('spinful')">
                <v-col cols="6">
                  <v-radio
                    color="hqsOrange"
                    data-cy="onsite_BCS_r"
                    label="Onsite BCS real"
                    value="onsite_BCS_r"
                  ></v-radio>
                </v-col>
                <v-col cols="6">
                  <v-radio
                    color="hqsOrange"
                    data-cy="onsite_BCS_i"
                    label="Onsite BCS img"
                    value="onsite_BCS_i"
                  ></v-radio>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3">
                  <v-radio
                    color="hqsOrange"
                    data-cy="none"
                    label="none"
                    value="none"
                  ></v-radio>
                </v-col>
              </v-row>

              <v-row v-if="latticeType !== 'spins'">
                <v-col cols="9">
                  <h5 class="scale pt-n15 text">Scale for site parameters</h5>
                  <v-img
                    contain
                    :src="require('../../assets/scale_blueOrangeYellow.png')"
                  ></v-img>
                </v-col>
              </v-row>
            </v-radio-group>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel data-cy="bond_param_panel" class="mt-n3">
          <v-expansion-panel-header class="text"
            >Bond Parameters</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-radio-group v-model="bondParam" class="pl-5" dense>
              <v-row>
                <v-col cols="3">
                  <v-radio
                    color="hqsOrange"
                    data-cy="none"
                    label="none"
                    value="none"
                  ></v-radio>
                </v-col>
              </v-row>

              <!--J-values-->
              <v-row v-if="latticeType !== 'spinless fermions'">
                <v-col cols="6">
                  <v-radio
                    color="hqsOrange"
                    data-cy="Jz"
                    label="Jz"
                    value="Jz"
                  ></v-radio>
                  <v-radio
                    color="hqsOrange"
                    data-cy="Jperp"
                    label="Jperp"
                    value="Jperp"
                  ></v-radio>
                  <v-radio
                    color="hqsOrange"
                    data-cy="Jcross"
                    label="Jcross"
                    value="Jcross"
                  ></v-radio>
                  <v-radio
                    color="hqsOrange"
                    data-cy="Jd"
                    label="Jd"
                    value="Jd"
                  ></v-radio>
                </v-col>
              </v-row>

              <!--spinless and spinful U value-->
              <!--currently not in use because qolossal does not need it -->
              <!-- <v-row v-if="latticeType !== 'spins'">
                <v-col cols="6">
                  <v-radio
                    color="hqsOrange"
                    data-cy="U"
                    label="U"
                    value="U"
                  ></v-radio>
                </v-col>
              </v-row> -->

              <!--spinless t and D values-->
              <v-row v-if="latticeType.includes('spinless')">
                <v-col cols="3">
                  <v-radio
                    color="hqsOrange"
                    data-cy="t_r_radio"
                    label="t real"
                    value="t_r"
                  ></v-radio>
                  <v-radio
                    color="hqsOrange"
                    data-cy="D_r_radio"
                    label="D real"
                    value="D_r"
                  ></v-radio>
                </v-col>

                <v-col cols="3">
                  <v-radio
                    color="hqsOrange"
                    data-cy="t_i_radio"
                    label="t img"
                    value="t_i"
                  ></v-radio>
                  <v-radio
                    color="hqsOrange"
                    data-cy="D_i_radio"
                    label="D img"
                    value="D_i"
                  ></v-radio>
                </v-col>
              </v-row>

              <!--spinful t values-->
              <v-row v-if="latticeType.includes('spinful')">
                <v-col cols="3" sm="6">
                  <v-radio
                    color="hqsOrange"
                    data-cy="t_uu_r_radio"
                    label="t ↑↑ real"
                    value="t_uu_r"
                  ></v-radio>
                  <v-radio
                    color="hqsOrange"
                    data-cy="t_du_r_radio"
                    label="t ↓↑ real"
                    value="t_du_r"
                  ></v-radio>
                </v-col>

                <v-col cols="3" sm="6">
                  <v-radio
                    color="hqsOrange"
                    data-cy="t_uu_i_radio"
                    label="t ↑↑ img"
                    value="t_uu_i"
                  ></v-radio>
                  <v-radio
                    color="hqsOrange"
                    data-cy="t_du_i_radio"
                    label="t ↓↑ img"
                    value="t_du_i"
                  ></v-radio>
                </v-col>

                <v-col cols="3" sm="6">
                  <v-radio
                    color="hqsOrange"
                    data-cy="t_ud_r_radio"
                    label="t ↑↓ real"
                    value="t_ud_r"
                  ></v-radio>
                  <v-radio
                    color="hqsOrange"
                    data-cy="t_dd_r_radio"
                    label="t ↓↓ real"
                    value="t_dd_r"
                  ></v-radio>
                </v-col>

                <v-col cols="3" sm="6">
                  <v-radio
                    color="hqsOrange"
                    data-cy="t_ud_i_radio"
                    label="t ↑↓ img"
                    value="t_ud_i"
                  ></v-radio>
                  <v-radio
                    color="hqsOrange"
                    data-cy="t_dd_i_radio"
                    label="t ↓↓ img"
                    value="t_dd_i"
                  ></v-radio>
                </v-col>
              </v-row>

              <!--spinful D values-->
              <v-row v-if="latticeType.includes('spinful')">
                <v-col cols="3" sm="6">
                  <v-radio
                    color="hqsOrange"
                    data-cy="D_uu_r_radio"
                    label="D ↑↑ real"
                    value="D_uu_r"
                  ></v-radio>
                  <v-radio
                    color="hqsOrange"
                    data-cy="D_du_r_radio"
                    label="D ↓↑ real"
                    value="D_du_r"
                  ></v-radio>
                </v-col>

                <v-col cols="3" sm="6">
                  <v-radio
                    color="hqsOrange"
                    data-cy="D_uu_i_radio"
                    label="D ↑↑ img"
                    value="D_uu_i"
                  ></v-radio>
                  <v-radio
                    color="hqsOrange"
                    data-cy="D_du_i_radio"
                    label="D ↓↑ img"
                    value="D_du_i"
                  ></v-radio>
                </v-col>

                <v-col cols="3" sm="6">
                  <v-radio
                    color="hqsOrange"
                    data-cy="D_ud_r_radio"
                    label="D ↑↓ real"
                    value="D_ud_r"
                  ></v-radio>
                  <v-radio
                    color="hqsOrange"
                    data-cy="D_dd_r_radio"
                    label="D ↓↓ real"
                    value="D_dd_r"
                  ></v-radio>
                </v-col>

                <v-col cols="3" sm="6">
                  <v-radio
                    color="hqsOrange"
                    data-cy="D_ud_i_radio"
                    label="D ↑↓ img"
                    value="D_ud_i"
                  ></v-radio>
                  <v-radio
                    color="hqsOrange"
                    data-cy="D_dd_i_radio"
                    label="D ↓↓ img"
                    value="D_dd_i"
                  ></v-radio>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="9">
                  <h5 class="scale pt-n15 text">Scale for bond parameters</h5>
                  <v-img
                    contain
                    :src="require('../../assets/scale_blueOrangeYellow.png')"
                    class="pb-3"
                  ></v-img>
                </v-col>
              </v-row>
            </v-radio-group>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </div>
</template>

<script>
const InfoDialog = () =>
  import(/* webpackChunkName: "dialogs" */ './InfoDialog')
import { mapMutations, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  components: {
    InfoDialog,
  },
  data: () => ({
    dialogMessage: 'magFieldLegend',
  }),
  methods: {
    ...mapMutations('lattice/latticeC4onfig', []),
  },
  computed: {
    ...mapFields('lattice', [
      'visibleMagField',
      'bondParam',
      'siteParam',
    ]),
    ...mapState('lattice', {
      latticeType: (state) => state.latticeType,
    }),
  },
}
</script>

<style scoped>
.scale {
  font-weight: bold;
}
.align_title {
  font-weight: bold;
  float: left;
}
.mag_img {
  clear: left;
  margin-left: 1em;
  margin-right: 1em;
  max-width: 300px;
}

.title-text {
  overflow-wrap: anywhere;
  word-wrap: break-word;
  word-break: normal;
  hyphens: auto;
}

</style>
